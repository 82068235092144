// Generated by Framer (4b8bcae)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, optimizeAppear, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/qacJMtbrGDTpaTF4eVfx/Ticker.js";
const TickerFonts = getFonts(Ticker);
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["nsTaYnHHL"];

const serializationHash = "framer-z6SCe"

const variantClassNames = {nsTaYnHHL: "framer-v-1e19vcj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0.4, duration: 1.6, ease: [0.44, 0, 0.21, 1.01], type: "tween"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 10}

const animation1 = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition2, x: 0, y: 0}

const animation2 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 10}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nsTaYnHHL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1e19vcj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"nsTaYnHHL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><MotionDivWithFX __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={optimizeAppear("animate", "fccqp2", animation1, "1e19vcj")} className={"framer-fccqp2-container"} data-framer-appear-id={"fccqp2"} exit={animation} initial={optimizeAppear("initial", "fccqp2", animation2, "1e19vcj")} layoutDependency={layoutDependency} layoutId={"sEHX8xKvK-container"} style={{transformPerspective: 1200}}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeWidth: 25, overflow: true}} gap={24} height={"100%"} hoverFactor={1} id={"sEHX8xKvK"} layoutId={"sEHX8xKvK"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={60} style={{height: "100%", width: "100%"}} width={"100%"}/></MotionDivWithFX></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-z6SCe.framer-1lckmxr, .framer-z6SCe .framer-1lckmxr { display: block; }", ".framer-z6SCe.framer-1e19vcj { height: 602px; overflow: hidden; position: relative; width: 1399px; }", ".framer-z6SCe .framer-fccqp2-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 602
 * @framerIntrinsicWidth 1399
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerW7QkwmNkY: React.ComponentType<Props> = withCSS(Component, css, "framer-z6SCe") as typeof Component;
export default FramerW7QkwmNkY;

FramerW7QkwmNkY.displayName = "Work";

FramerW7QkwmNkY.defaultProps = {height: 602, width: 1399};

addFonts(FramerW7QkwmNkY, [{explicitInter: true, fonts: []}, ...TickerFonts], {supportsExplicitInterCodegen: true})